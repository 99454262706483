<template>
  <div class="container mb-3">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6" v-for="(item, index) in $store.state.naku.packets" :key="index">
        <div
          class="card bg-light"
          @click="
            () => {
              nakuPaymentDialog = true;
              packet = item;
            }
          "
          style="cursor: pointer"
        >
          <div class="card-body">
            <div class="new-arrival-product">
              <div class="new-arrivals-img-contnent">
                <img :src="item.Image" alt="" style="object-fit:contain;" />
              </div>
              <div class="new-arrival-content text-center mt-3">
                <h2 class="">{{ item.NakuCount }} <strong>NA</strong>KU</h2>
                <span class="price text-white">{{ item.Price }} ₺</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <h2>Kullanım Özeti</h2>
        <div
          class="
            col-12
            d-flex
            justify-content-between
            summary-list
            bg-light
            align-items-center
            mb-4
          "
          v-for="(item, index) in displayedHistory"
          :key="index"
        >
          <div class="d-flex align-items-center">
            <div class="summary-img me-3">
              <img v-if="item.UsageType == 0 || item.UsageType == 1" src="../../assets/images/smalllogo.svg" alt="" />
              <img v-else src="../../assets/images/naku.svg" alt="" />
            </div>
            <div>
              <p class="summary-date">
                {{ $moment(item.CreatedDateTime).format("LL") }}
              </p>
              <p class="summary-type">{{ item.Title }}</p>
            </div>
          </div>
          <div class="naku-amount bg-dark border-radius">
            <span class="font-w600 fs-16">
              <span class="p-0">{{ item.UsageType != 0 && item.UsageType != 1 && item.UsageType != 6 && item.UsageType != 7 ? "+" : "-" }}</span
              >{{ item.Naku }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-center" v-if="pages.length > 1">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <button class="page-link" type="button" aria-label="Previous" @click="page--">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </button>
            </li>
            <li v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index" @click="page = pageNumber" class="page-item" :class="{ active: pageNumber == page }">
              <button type="button" class="page-link text-white active">
                {{ pageNumber }}
              </button>
            </li>
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next" @click="page++">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <el-dialog :close-on-click-modal="false" class="sm-modal" title="Naku Satın Al" :visible.sync="nakuPaymentDialog">
        <p class="text-white">Bu işleme devam ederseniz hesabınıza {{ this.packet.NakuCount }} NAKU satın alınacaktır. Bu işlemi onaylıyor musunuz?</p>
        <div class="d-flex justify-content-center align-items-center text-white">
          <span class="fs-20 fw-bold"> {{ this.packet.NakuCount }}</span>
          <img src="../../assets/images/naku.svg" alt="" style="width: 100px" />
        </div>
        <span slot="footer" class="dialog-footer d-flex">
          <el-button class="bg-transparent px-5 py-3" @click="nakuPaymentDialog = false">İptal</el-button>
          <el-button class="px-5 py-3" type="primary" @click="paymentStart(packet)">Satın Al</el-button>
        </span>
      </el-dialog>

      <el-dialog :close-on-click-modal="false" class="sm-modal" title="Ödeme" :visible.sync="iyzicoPaymentDialog" :before-close="handleIyzicoPaymentDClose">
        <span class="text-blue">Bu sayfada verdiğiniz bilgiler hiçbir şekilde sistem tarafından kaydedilmemekte olup, iyzico güvencesiyle alınan ödemelerin sadece ücretleri tarafımıza aktarılmaktadır.</span>

        <div class="row mt-3">
          <div class="col-12 mb-3">
            <label> Kartın Üzerinde Yazan Ad Soyad</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': buyNakuResponse.ValidationErrors.some((x) => x.Key == 'HolderName'),
              }"
              placeholder="Ad Soyad"
              v-model="paymentFormInputs.HolderName"
            ></el-input>
            <span class="validation-error" v-if="buyNakuResponse.ValidationErrors.some((x) => x.Key == 'HolderName')">{{ buyNakuResponse.ValidationErrors.filter((x) => x.Key == "HolderName")[0].Value }}</span>
          </div>
          <div class="col-6 mb-3">
            <label>Ay/Yıl</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': buyNakuResponse.ValidationErrors.some((x) => x.Key == 'ExpiryYear' || x.Key == 'ExpiryMonth'),
              }"
              placeholder="AA/YY"
              v-mask="['##/##']"
              v-model="paymentFormInputs.Expiry"
            >
            </el-input>
            <span class="validation-error" v-if="buyNakuResponse.ValidationErrors.some((x) => x.Key == 'ExpiryYear' || x.Key == 'ExpiryMonth')">{{ buyNakuResponse.ValidationErrors.filter((x) => x.Key == "ExpiryYear" || x.Key == "ExpiryMonth")[0].Value }}</span>
          </div>
          <div class="col-6 mb-3">
            <label>CVV/CVC</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': buyNakuResponse.ValidationErrors.some((x) => x.Key == 'Cvc'),
              }"
              placeholder="***"
              v-mask="['###']"
              v-model="paymentFormInputs.Cvc"
            ></el-input>
            <span class="validation-error" v-if="buyNakuResponse.ValidationErrors.some((x) => x.Key == 'Cvc')">{{ buyNakuResponse.ValidationErrors.filter((x) => x.Key == "Cvc")[0].Value }}</span>
          </div>
          <div class="col-12 mb-3">
            <label>Kart Numarası</label>
            <el-input
              class="form-control"
              :class="{
                'input-validation-error': buyNakuResponse.ValidationErrors.some((x) => x.Key == 'CardNo'),
              }"
              placeholder="____ ____ ____ ____"
              v-mask="['####-####-####-####']"
              v-model="paymentFormInputs.CardNo"
            >
            </el-input>
            <span class="validation-error" v-if="buyNakuResponse.ValidationErrors.some((x) => x.Key == 'CardNo')">{{ buyNakuResponse.ValidationErrors.filter((x) => x.Key == "CardNo")[0].Value }}</span>
          </div>
          <div class="col-12 mb-3 text-white fs-18">
            <small class="text-white">Tutar:</small>
            <strong class="text-white fs-18"> <span class="ms-2"></span> {{ this.selectPacket.Price }}₺ </strong>
            / {{ this.selectPacket.NakuCount }} <strong>NA</strong><span>KU</span>
          </div>
          <div class="col-12">
            <div class="d-flex">
              <input type="checkbox" @click="handleCheckbox" v-model="contract" class="form-check-input" style="width: 1.25rem !important; height: 1.25rem !important" />
              <label class="form-check-label">
                <span @click="contractDialog = true" class="policy-text">Mesafeli Satış Sözleşmesini</span>
                okudum, anladım kabul ediyorum.</label
              >
            </div>
          </div>
          <div class="col-md-12">
            <img class="w-100 my-3" src="../../assets/images/iyzico-banner.svg" alt="" />
          </div>
        </div>
        <div id="threed-html" v-html="ThreedHtml"></div>

        <span slot="footer" class="dialog-footer">
          <el-button :loading="loading" class="py-3 w-100" @click="paymentEnd(packet)">Ödeme Yap</el-button>
        </span>
      </el-dialog>
      <el-dialog :close-on-click-modal="false" class="sm-modal" :title="contractInfo.Title" :visible.sync="contractDialog">
        <div v-html="contractInfo.Content"></div>
        <span slot="footer" class="dialog-footer">
          <el-button class="accept-button" @click="handleAcceptPolicy"> Kabul et ve kapat</el-button>
        </span>
      </el-dialog>
      <el-dialog :close-on-click-modal="false" class="sm-modal" title="Satın Alındı" :visible.sync="successPaymentDialog">
        <img class="w-100 mb-3" src="../../assets/images/ilanolusturuldu.svg" alt="" />
        <span class="text-white w-100 m-auto"
          >Tebrikler! 1x
          <img src="../../assets/images/naku.svg" style="width: 80px" alt="" />
          satın aldınız</span
        >
        <span slot="footer" class="dialog-footer">
          <el-button class="w-100" @click="successPaymentDialog = false">Kapat</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import META_DATA from "../../constants/metaData";
import POLICIES from "../../constants/policies";

export default {
  metaInfo() {
    return {
      title: META_DATA.SHOP.Title,
    };
  },
  data() {
    return {
      nakuPaymentDialog: false,
      successPaymentDialog: false,
      nakuPackets: null,
      packetId: "",
      packet: "",
      selectPacket: "",
      page: 1,
      perPage: 9,
      pages: [],
      paymentFormInputs: {
        PacketId: "",
        OkUrl: 'https://api.nakliyekullan.com/' + "callback/callback",
        FailUrl: 'https://api.nakliyekullan.com/' + "callback/callback",
        CallbackUrl: 'https://api.nakliyekullan.com/' + "callback/callback",
        HolderName: "",
        CardNo: "",
        Expiry: "",
        ExpiryYear: "",
        ExpiryMonth: "",
        Cvc: "",
        Installement: 1,
        FromWeb: true,
      },
      ThreedHtml: "",
      contract: false,
      contractDialog: false,
      iyzicoPaymentDialog: false,
      buyNakuResponse: { ValidationErrors: [] },
    };
  },

  beforeMount() {
    if (this.$store.state.User.token) {
      this.$store.dispatch("getNakuPackets");
      this.nakuUsages();
    }
  },
  created() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.success === "true") window.sendNotification.success("Ödemeniz başarı ile gerçekleştirildi!");
    else if (params.success === "false") window.sendNotification.error(params.message ? params.message : "Ödemeniz gerçekleştirilemedi!");
  },
  methods: {
    handleCheckbox(e) {
      window.sendNotification.warning("Onaylamak için okumanız gerekmektedir");
      e.preventDefault();
      e.stopPropagation();
    },
    handleIyzicoPaymentDClose() {
      this.iyzicoPaymentDialog = false;
      this.buyNakuResponse = { ValidationErrors: [] };
      this.contract = false;
    },
    handleAcceptPolicy() {
      this.contractDialog = false;
      this.contract = true;
    },
    async paymentStart(packets) {
      const result = await this.$store.dispatch("checkInvoiceInfo");
      if (result.Data) {
        this.selectPacket = packets;
        this.nakuPaymentDialog = false;
        this.iyzicoPaymentDialog = true;
      } else {
        window.sendNotification.error("NAKU satın alabilmek için fatura bilgilerinizi doldurmalısınız!");
        // redirect to invoice page
        this.$router.push({ name: 'home.profil', query: { invoiceDialog: true } })
      }
    },
    
    async paymentEnd(packets) {
      if (!this.contract) {
        window.sendNotification.error("Devam etmek için sözleşmeyi kabul edin!");
        return;
      }
      if (this.paymentFormInputs.Expiry && this.paymentFormInputs.Expiry !== "") {
        this.paymentFormInputs.ExpiryMonth = this.paymentFormInputs.Expiry.substring(0, 2);
        this.paymentFormInputs.ExpiryYear = this.paymentFormInputs.Expiry.substring(3);
      }
      const fixedCardNo = this.paymentFormInputs.CardNo.replace("-", "")
        .replace("-", "")
        .replace("-", "")
        .replace("-", "");

      const result = await this.$store.dispatch("buyNaku", {
        ...this.paymentFormInputs,
        CardNo: fixedCardNo,
        PacketId: packets.ID,
      });

      if (result.Data && result.Data.ThreeD) {
        this.ThreedHtml = result.Data.ThreedHtml;
        this.iyzicoPaymentDialog = false;
        window.$("body").css("background-color", "var(--bs-dark)");
        this.$store.dispatch("iyzico3DForm");
      } else if (result.ValidationErrors && result.ValidationErrors.length > 0) {
        this.buyNakuResponse = result;
      }
    },
    nakuUsages() {
      this.$store.dispatch("nakuUsages", {});
    },
    setPages() {
      let numberOfPages = Math.ceil(this.nakuHistory.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(nakuhistory) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return nakuhistory.slice(from, to);
    },
  },
  computed: {
    contractInfo: {
      get() {
        const cookie = this.$store.getters["policies"].find((policy) => policy.Type === POLICIES.PAYMENT);
        return cookie ? cookie : { Title: "", Content: "" };
      },
    },
    displayedHistory() {
      return this.paginate(this.nakuHistory);
    },
    nakuHistory: {
      get() {
        return this.$store.getters["nakuHistory"];
      },
      set(newValue) {
        this.$store.commit("nakuHistory", newValue);
      },
    },
    loading() {
      return this.$store.getters["loading"];
    },
  },
  watch: {
    nakuHistory() {
      this.setPages();
    },
    page() {
      if (this.page == 0) {
        this.page = this.pages.length;
      } else if (this.page > this.pages.length) {
        this.page = 1;
      }
    },
  },
};
</script>

<style lang="scss">
#threed-html {
  visibility: hidden;
}
@import "./style.scss";

button.page-link {
  display: inline-block;
}
</style>
